import { getInstance, type ICache } from '../common/cache';
import { getLocalStorageItem, setLocalStorageItem } from '../common/persist-helpers';

interface RefreshOnAccessArgs {
	/**
	 * Is the identifier for the name in the local storage.
	 */
	name: string;
}

/**
 * Cache implementation which always returns the persited response (if available).
 * But refreshes the response everytime its requested, so that next time onwards the data is up to date.
 *
 * Note - This will also return an expired result. This strategy should be used only in scenarios where:
 * 1. The data changes rarely.
 * 2. Its fine to serve the expired result to the user.
 */
export const promiseCache = <T>(args: ICache & RefreshOnAccessArgs) => {
	const { name } = args;
	const cacheInstance = getInstance<T>(args);
	const persistedState = getLocalStorageItem(name);

	if (persistedState) {
		cacheInstance.deserialize(persistedState);
	}

	const get = (key: string, returnExpired?: boolean) => {
		const storedValue = cacheInstance.get(key, returnExpired ?? true); // fetch expired result by default
		const isExpired = cacheInstance.isExpired(key);

		return { storedValue, isExpired };
	};

	const set = async (key: string, result: Promise<T>, expiration?: number) => {
		cacheInstance.set(key, result, expiration);
		await result;
		const serialized = await cacheInstance.serialize();
		setLocalStorageItem(name, serialized);
	};

	const refreshOnAccessDecorator = ({
		key,
		supplier,
		returnExpired,
		onlyRefreshIfExpired,
		expiration,
	}: {
		key: string;
		supplier: () => Promise<T>;
		returnExpired?: boolean;
		onlyRefreshIfExpired?: boolean;
		expiration?: number;
	}) => {
		const { storedValue, isExpired } = get(key, returnExpired);

		const shouldRefresh = onlyRefreshIfExpired ? isExpired : true;

		if (!storedValue || shouldRefresh) {
			const apiPromise = supplier();
			set(key, apiPromise, expiration);

			// If there was already a stored value prior to refreshing, return it
			return storedValue || apiPromise;
		} else {
			return storedValue;
		}
	};

	return {
		get,
		refreshOnAccessDecorator,
	};
};
